import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EntryTitle from "../components/entry-title"
import { rhythm } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  // 前の記事ボタン
  let previousButton = "";
  if (previous) {
    previousButton = (
      <div className="changes-page past-page">
        <p>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              {previous.frontmatter.title}
            </Link>
          )}
        </p>
      </div>
    )
  }

  // 次の記事ボタン
  let nextButton = "";
  if (next) {
    nextButton = (
        <div className="changes-page new-page">
          <p>
            {next && (
              <Link to={next.fields.slug} rel="next" >
                {next.frontmatter.title}
              </Link>
            )}
          </p>
        </div>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        url={post.fields.slug}
      />
      <article className="entry">
        <EntryTitle 
            frontmatter={post.frontmatter}
            fields={post.fields}
        />
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav>
        <ul className="page-movement"
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>{previousButton}</li>
          <li>{nextButton}</li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        description
        category
        tags
      }
      fields {
        slug
        category
        tags
      }
    }
  }
`
